<template>
  <metainfo></metainfo>
  <template v-if="isForbiddenPage()">
    <NoPermission />
  </template>
  <template v-else>
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col-sm">
            <h2 class="page-title">
              Категории
            </h2>
          </div>
          <div class="col-auto">
            <button class="btn btn-primary" data-bs-toggle="modal" data-type="create" :data-bs-target="'#modal-category'">
              <IconCirclePlus/>
              Создать
            </button>
          </div>
          <div class="col-auto">
            <router-link :to="{name: 'Shops'}" class="btn btn-green">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="icon icon-tabler icons-tabler-outline icon-tabler-shopping-bag">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path
                    d="M6.331 8h11.339a2 2 0 0 1 1.977 2.304l-1.255 8.152a3 3 0 0 1 -2.966 2.544h-6.852a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304z"/>
                <path d="M9 11v-5a3 3 0 0 1 6 0v5"/>
              </svg>
              Магазины
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div ref="scrollComponent">
          <CategoryList :query="getQuery()" @onRender="onRender" :clear-result="getClearResult()"/>
        </div>
      </div>
    </div>
    <Teleport to="body">
      <Modal @onUpdate="onUpdate" @onCreate="onCreate" @onDelete="onDelete"/>
    </Teleport>
  </template>
</template>
<script>
import {defineComponent, onMounted, onUnmounted, ref} from 'vue'
import CategoryList from "@/modules/shops/pages/categories/components/list/Index"
import Modal from '@/modules/shops/pages/categories/components/modals/create_update/Index'
import IconCirclePlus from '@/modules/shops/icons/IconCirclePlus'
import {useMeta} from "vue-meta"
import Helper from "@/services/Helper"
import Permission from "@/modules/users/services/Permission"
import NoPermission from "@/modules/auth/pages/no_permission/Index"

export default defineComponent({
  name: "Items",
  components: {
    NoPermission,
    CategoryList,
    Modal,
    IconCirclePlus,
  },
  setup() {
    useMeta({title: "Категории"})

    const scrollComponent = ref(null)

    let query = ref({})
    let clearResult = ref(false)
    let offset = ref(0)
    let eofResult = ref(false)
    let countPosts = ref(0)
    let isLoading = ref(false)
    let noPermission = ref(false)
    let scroll = () => {
      isLoading.value = true
      offset.value += 18

      onLoadMore(offset.value)
    }
    let params = {
      search: "",
      categories: [],
      enable: true,
    }

    function onRender(payload) {
      eofResult.value = payload["oef_items"] === true
      if (payload["count_items"] > 0) {
        countPosts.value = payload["count_items"];
      }
      clearResult.value = false
      isLoading.value = false
    }

    function onFilter(q) {
      clearResult.value = true
      offset.value = 0
      eofResult.value = false
      countPosts.value = 0

      q = Helper.mergeObject(q, {
        "limit": 18,
        "offset": offset.value,
      })

      q = Helper.clone(q)

      query.value = q
    }

    function onLoadMore(offset) {
      if (Helper.isEmptyObject(query.value)) {
        return false
      }

      let q = Helper.clone(query.value)

      query.value = Helper.mergeObject(q, {
        "offset": offset,
      })
    }

    const handleScroll = () => {
      if (eofResult.value) return

      let element = scrollComponent.value

      if (!isLoading.value && element.getBoundingClientRect().bottom < window.innerHeight) {
        scroll()
      }
    }

    onMounted(() => {
      if (!checkPermission()) {
        return
      }

      query.value = {
        "limit": 18,
        "offset": 0,
      }
      window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })


    function checkPermission() {
      if (!Permission.hasPermissions("shops")) {
        setNoPermission()
        return false
      }

      return true
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function isForbiddenPage() {
      return noPermission.value
    }

    function getParams() {
      return params
    }

    function getQuery() {
      return query.value
    }

    function getClearResult() {
      return clearResult.value
    }

    function onUpdate() {
      onFilter({})
    }

    function onCreate() {
      onFilter({})
    }

    function onDelete() {
      onFilter({})
    }

    return {
      getParams,
      getQuery,
      getClearResult,
      onRender,
      onFilter,
      scrollComponent,
      onUpdate,
      onCreate,
      onDelete,
      isForbiddenPage,
    }
  }
})

</script>

<style scoped src="./css/style.css"></style>
<script setup lang="ts">
</script>