<template>
  <li class="nav-item">
    <router-link class="nav-link" :to="{ name: 'IndexComment' }" v-if="isView()">
      <span class="nav-link-icon d-md-none d-lg-inline-block">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-message"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 9h8" /><path d="M8 13h6" /><path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" /></svg>
      </span>
      <span class="nav-link-title">
        Комментарии
      </span>
    </router-link>
  </li>
</template>

<script>
import Permission from "@/modules/catalog/services/Permission"
import {onMounted, ref} from "vue"

export default {
  name: "Comments",
  setup() {
    let noPermission = ref(false)

    onMounted(() => {
      checkPermission()
    })

    function checkPermission() {
      console.log("comments: ", Permission.hasPermissions("comments"))
      
      if (!Permission.hasPermissions("comments")) {
        setNoPermission()
      }
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function isView() {
      return !noPermission.value
    }

    return {
      isView,
    }
  },
}
</script>

<style scoped>

</style>